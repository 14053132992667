.results {
    margin: 10px;
}

.detail-description {
    margin: 0 10px;
}

.detail-description-header {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.detail-notes {
    margin: 0 10px;
}

.detail-notes-header {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.details-header {
    margin: 0 10px;
}

.detail-line {
    flex: 1;
    display: flex;
    margin: 0 10px;
    padding: 10px 0;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
}

.detail-line-left {
    color: #666;
    font-weight: bold;
}

.detail-line-right {
    color: #008ec2;
    font-weight: bold;
    text-align: right;
}

.loading {
    display: inline-block;
    font-size: 2em;
    animation-name: loading;
    animation-duration: 1s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.relative {
    position: relative;
}

.overlay {
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    text-align: center;
}

.overlay .loading {
    font-size: 3em;
}

.hidden {
    display: none;
}

@keyframes loading {
    0% {
        transform: rotate(-3deg) scale(1);
    }
    25% {
        transform: rotate(0deg) scale(1.1);
    }
    50% {
        transform: rotate(3deg) scale(1);
    }
    75% {
        transform: rotate(0deg) scale(1.1);
    }
    100% {
        transform: rotate(-3deg) scale(1);
    }
}

.btn.spaced {
    margin: 0 5px;
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 90;
}

.video-toolbar {
    padding: 1em 0.5em 0;
    background: #eee;
    text-align: right;
}

.video-toolbar * {
    margin: 0 0.25em;
}

.video-toolbar button {
    font-weight: bold;
    white-space: nowrap;
}

.dad-video-player .video-react {
    padding-bottom: 3.2em;
}

.dad-video-player .video-react .video-react-video {
    position: static;
}

.dad-video-player .video-react .video-react-control-bar {
    position: static;
}

.dad-video-player .video-toolbar .thumbnail-bar {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    font-size: 0;
}

.dad-video-player .video-toolbar .thumbnail-bar .thumbnail-box {
    display: inline-block;
    position: relative;
    margin: 0 2px;
}

.dad-video-player .video-toolbar .thumbnail-bar img.thumbnail-image {
    max-width: 100px;
    cursor: pointer;
}

.dad-video-player .video-react-fullscreen-control {
    display: none;
}

.short-number {
    max-width: 80px;
}

.video-react-mouse-display:after {
    top: 2em !important;
    background: black !important;
}

.video-react .video-react-play-progress:before {
    /*top: 0em !important;*/
    top: -0.225em !important;
    font-size: 2em !important;
}

.video-react .video-react-progress-holder .video-react-load-progress {
    height: 0.9em !important;
}

.video-react .video-react-progress-holder,
.video-react .video-react-progress-holder .video-react-play-progress {
    height: 0.9em !important;
}

@media (min-width: 1200px) {
    html {
        font-size: 10px;
    }

    .video-react-current-time.video-react-time-control.video-react-control,
    .video-react-time-control.video-react-time-divider > div,
    .video-react-time-control.video-react-time-divider > div > span,
    .video-react-duration-display,
    .video-react-volume-menu-button-vertical.video-react-vol-3.video-react-volume-menu-button.video-react-menu-button-popup.video-react-control.video-react-button.video-react-menu-button,
    .video-react-play-control.video-react-control.video-react-button,
    .video-react-play-progress.video-react-slider-bar,
    .video-react-current-time-display {
        font-size: 1.2rem;
        line-height: 3rem;
    }
}

@media (min-width: 1900px) {
    html {
        font-size: 10px;
    }

    .video-react-current-time.video-react-time-control.video-react-control,
    .video-react-time-control.video-react-time-divider > div,
    .video-react-time-control.video-react-time-divider > div > span,
    .video-react-duration-display,
    .video-react-volume-menu-button-vertical.video-react-vol-3.video-react-volume-menu-button.video-react-menu-button-popup.video-react-control.video-react-button.video-react-menu-button,
    .video-react-play-control.video-react-control.video-react-button,
    .video-react-play-progress.video-react-slider-bar,
    .video-react-current-time-display {
        font-size: 1.4rem;
        line-height: 3rem;
    }
}
