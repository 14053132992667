.layout .full-height {
    position: relative;
    min-height: calc(100vh - 100px);
}

#page-container {
    position: relative; /*so as to set its children's position as absolute*/
    min-height: 100vh; /*100% of the viewport*/
}
#content-wrap {
    /* Footer height - ensuring that exactly enough space is left for the footer inside the page-container they are both in*/
    padding-bottom: 4rem;
}
