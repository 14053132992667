div.jumbotron {
    color: black;
    margin: 15px auto;
    background-color: white;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

@media screen and (min-width: 768px) {
    div.jumbotron {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.navbar {
    margin-bottom: 0;
}

.modal-content {
    color: black;
    border-radius: 20px;
}

body {
    background: black;
    color: white;
}

.glowing-border {
    border: 2px solid blue;
    /*border-radius: 10px;*/
    box-shadow: 0 0 10px blue;
}
.glowing-border:focus {
    outline: none;
    border-color: darkblue;
    box-shadow: 0 0 10px blue;
}

.navbar {
    background-color: black;
}

.navbar-default .navbar-nav > li > a {
    color: white;
    text-decoration: none;
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
    color: darkgray;
}

.navbar-default .navbar-brand {
    color: white;
}
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
    color: darkgray;
}

.no-caps {
    text-transform: none;
}

.backlit {
    box-shadow: 0 0 400px 100px rgba(255, 255, 255, 0.5);
}

.big-button {
    color: white;
    font-size: 50px;
    margin-bottom: 30px;
    background: linear-gradient(gray, black);
    border-radius: 20px;
}
.big-button:hover,
.big-button:active {
    color: darkblue;
    background: linear-gradient(darkgray, black);
}
