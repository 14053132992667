#login-page{
    margin-top: -20px;
    margin-bottom: -15px;
    min-height: calc(100vh - 135px);
}

#login-page button{
    margin-top: 20px;
    margin-bottom: 20px;
}

form#signin{
    padding-top: 40px;
    max-width: 700px;
    margin: 0 auto;
}

form#signin .help-block{
    color: #333;
}

.btn-default, .btn-default.btn-donate, .btn-default[disabled]{
    border: 0px;
}

.btn-default[disabled]:hover{
    background-color: #8fc5e0;
}

.form-horizontal .control-label.text-left{
    text-align: left;
}