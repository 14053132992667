footer {
    background-color: rgb(0, 0, 0);
    /*absolute to page-container, (not the viewport) so it can move down when page-container height > that of viewport*/
    position: absolute;
    bottom: 0; /* sticking to the bottom of page-container.*/
    width: 100%;
    height: 4rem;
    color: antiquewhite;
    border-top: 0.1rem solid antiquewhite;
    margin: auto;
    padding: 10px;
    text-align: center;
}
